export default function ContactUs() {
    return (
        <section className="relative py-20 2xl:py-40 bg-gray-800" id="ContactUs">
            <img className="hidden lg:block absolute top-0 left-0 w-2/5" src="zospace-assets/images/map.svg" alt="" />
            <img className="hidden lg:block absolute top-0 left-0 mt-40 ml-40" src="zospace-assets/images/office-tag.svg" alt="" />
            <div className="relative container px-4 mx-auto">
                <div className="text-center mb-20">
                    <span className="text-lg text-white font-AzoSansMedium">Have a question?</span>
                    <h2 className="mt-10 text-5xl font-AzoSansBold text-white">Contact us</h2>
                </div>
                <div className="max-w-4xl mx-auto items">
                    <div className="flex flex-wrap -mx-4 mb-20">
                        <div className="w-0 lg:w-1/4 px-4 mb-4 lg:mb-0">
                            
                        </div>
                        <div className="w-full lg:w-1/2 px-4">
                            <div className="py-6 px-8 mb-4 bg-gray-600 rounded-lg">
                                <div className="flex items-center">
                                    <span className="flex items-center justify-center w-12 h-12 mr-8 bg-brandblue rounded-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
</svg>
                                    </span>
                                    <a className="text-lg font-AzoSansLight text-white no-underline" href='tel:+447916345907'>07916345907</a>
                                </div>
                            </div>
                            <div className="py-6 px-8 mb-4 bg-gray-600 rounded-lg">
                                <div className="flex items-center">
                                    <span className="flex items-center justify-center w-12 h-12 mr-8 bg-brandblue rounded-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
</svg>
                                    </span>
                                    <a className="text-sm font-AzoSansLight text-white justify-center no-underline" href="mailto:info@amyrowlandisw.com">Info@Amyrowlandisw.com</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}