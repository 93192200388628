/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon, DocumentTextIcon, PaperClipIcon, UsersIcon, ChartBarIcon, ClipboardCheckIcon } from '@heroicons/react/outline'

const features = [
    {
        name: 'Social Work/Parenting assessments',
        description:
            'I complete comprehensive, analytical assessments of parenting capacity, drawing upon the Framework for the Assessment of Children in Need and their Families as well as utilising an up-to-date knowledge of relevant research, tools and methods drawn from social work practice. I work from a child-centred perspective, whilst adopting a sensitive and empathetic approach to working with those being assessed.',
        icon: PaperClipIcon,
    },
    {
        name: 'Protective parent/carer assessment',
        description:
            'Drawing upon research and practice guidance developed by the Lucy Faithful Foundation, the protective carer assessment is utilised in the context of child sexual abuse and focuses upon and assesses the ability of the non-abusing parents/carer to protect the child from the risk of sexual abuse. The assessment process also includes a teaching element, to raise awareness of key issues and impacts of sexual abuse.',
        icon: DocumentTextIcon,
    },
    {
        name: 'Assessment of parents with learning difficulties',
        description:
            'I am qualified to undertake specialist assessments of parents with learning disabilities using both the PAMs and ParentAssess models.',
        icon: AnnotationIcon,
    },
    {
        name: 'Connected carer assessment and special guardianship reports',
        description:
            'I undertake connected person assessments of family and/or friends to determine their suitability as short or long-term carers for a child/ren. The connected carer assessment will pay particular attention to the motivation to care for the child, the ability to meet the needs of the child and their ability/willingness to promote a relationship with parents and important people in the childs’ life. ',
        icon: ChartBarIcon,
    },
    {
        name: 'Sibling relationship assessments',
        description:
            'It is widely accepted that brothers and sisters will often have the most enduring, long-lasting relationships and as such, there is a presumption that they will be raised together. There are however, occasions when this cannot happen, or when it is not in their best interests for a range of reasons. The permanent separation of brothers and sisters is a decision that must be very carefully considered, robustly assessed and clearly rationalised. Utilising the CoramBAAF Beyond Together or Apart framework, I am experienced in undertaking sibling relationship assessments to determine whether brothers and sisters can and should remain together, whilst also considering the future contact needs and recommendations if they cannot live together.',
        icon: UsersIcon,
    },
    {
        name: 'Other Services',
        description: <ul className="list-disc">
            <li>Direct work with children</li>
            <li>Pre-birth assessments</li>
            <li>Parenting intervention</li>
            <li>Life story work</li>
            <li>Supervised contact</li>
            <li>Risk assessments</li>
            <li>Contact assessments</li>
        </ul>,
        icon: ClipboardCheckIcon,
    },
]

export default function Services() {
    return (
        <div className="bg-brandblue py-6" id='Services'>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
                        Our Services
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">

                    </p>
                </div>

                <div className="mt-16">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative">
                                <dt>
                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-white  text-brandblue">
                                        <feature.icon className="h-6 w-6" aria-hidden="true" />
                                    </div>
                                    <p className="ml-16 text-lg leading-6 font-AzoSansBold text-white">{feature.name}</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-sm font-AzoSansLight text-white">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
