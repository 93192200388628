export default function Footer() {
    return (

        <footer className="p-4 bg-brandblue rounded-lg shadow h-42 mb-0 bottom-0">
            <div className="sm:flex sm:items-center sm:justify-between overflow-hidden">
                <a href="https://amyrowlandisw.com" className="flex items-center mb-4 sm:mb-0">
                    <img src={require('../Images/Logo-02.png')} className="ml-16 md:ml-0 md:mr-3 h-8" alt="ARISW Logo" />

                </a>

                <ul className="flex flex-wrap mb-6 text-sm text-white sm:mb-0 no-underline font-AzoSansLight">
                    <li>
                        <a href="#" className="mr-4 no-underline md:mr-6 font-AzoSansLight text-white">Home</a>
                    </li>
                    <li>
                        <a href="#aboutus" className="mr-4 no-underline md:mr-6 text-white">About Us</a>
                    </li>
                    <li>
                        <a href="#Services" className="mr-4  no-underline md:mr-6 text-white">Our Services</a>
                    </li>
                    <li>
                        <a href="#ContactUs" className="no-underline text-white">Contact</a>
                    </li>
                </ul>
            </div>
            <hr className="my-0 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-0 items-center" />
            <span className="flex items-center mb-2 sm:mb-0 text-white">© 2022 <a href="https://amyrowlandisw.com" className="">Amy Rowland ISW</a>. All Rights Reserved.
            </span>
            <span className="flex items-center mb-0 sm:mb-0 text-white">Website By <a href="https://sjdevelopment.co.uk" className="ml-2">SJ Development</a>
            </span>
        </footer>

    )
}