import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'

export default function Banner() {

    return (
        <section className="relative bg-gray-800 overflow-y-visible">
            <div className="absolute bottom-0 inset-x-0 h-full bg-gradient-zospace-1"></div>
            <div className="absolute bottom-0 inset-x-0 h-3/5 w-2full -ml-64 -mb-12 bg-gradient-zospace-2 transform -rotate-6"></div>
            <div className="relative container px-4  mx-auto">

                <div className="flex items-center">
                    <div className="static items-center justify-center">
                        <h2 className="max-w-lg lg:max-w-lg 2xl:max-w-none mt-7 mb-7 mb:mb-2 text-5xl lg:text-6xl 2xl:text-8xl text-white font-AzoSansLight">DELIVERING INDEPENDENT EXPERTISE</h2>
                        
                    </div>

                    <div className=' hidden md:block mb-2 ml-4'>
                        <img src={require('../Images/image1.jpg')} alt="image" loading="lazy" width="" className='rounded-lg object-contain hidden md:block' height=""  />
                    </div>

                </div>
            </div>
        </section>
    )
}