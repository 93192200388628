export default function AboutUs(){
    return(
        <div className="py-16 bg-white" id='aboutus'>  
  <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
      <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
        <div className="md:5/12 lg:w-5/12">
          <img src={require('../Images/image2.jpg')} alt="image" loading="lazy" width="" className='rounded-lg object-contain hidden md:block' height="" />
        </div>
        <div className="md:7/12 lg:w-6/12">
          
          <p className="mt-0 text-brandblue font-AzoSansLight">I am a highly motivated and experienced social worker, having qualified in 2013. Over the last twenty-years, I have worked within the safeguarding and child protection field in a variety of roles including as a family support worker, social worker, advanced practitioner and practice manager. I currently work both as a children’s guardian and as an independent social worker undertaking a range of fair and balanced, evidence based, analytical assessment of risk and parenting capacity.</p>
          <p className="mt-2 text-brandblue font-AzoSansLight">I am registered with Social Work England and I am a member of BASW</p>
        </div>
      </div>
  </div>
</div>
    )
}