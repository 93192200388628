import logo from './logo.svg';
import './App.css';
import NavBar from './Components/navbar'
import "bootstrap/dist/css/bootstrap.min.css";
import Banner from './Components/Banner';
import Footer from './Components/Footer';
import ContactUs from './Components/contactus';
import Services from './Components/Services';
import AboutUs from './Components/aboutus';
import Hotjar from '@hotjar/browser';
function App() {
  const siteId = 3088906;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
  return (
    <div className="flex flex-col h-screen w-screen justify-between overflow-x-clip scrollbar-hide bg-gray-800">
      <NavBar />
      <Banner />
      <AboutUs />
      <Services />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
